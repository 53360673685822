import { useContext, useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Course, ResourceDataElement } from "../models/course";
import { PublicVideo, Video } from "../models/video";
import { axiosAuthInstance, axiosAuthPublicInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../config/Configuration";
import PublicLayout from "../layout/PublicLayout";
import { PrimaryButton } from "../components/PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";
import { FeauturedProducts } from "../components/FeauturedProducts";
import { SubscriptionBanner } from "../components/SubscriptionBanner";
import { CartContext } from "../core/cartProvider";
import { AuthService } from "../auth/sso/auth.service";
import axios from "axios";
import { CertificationModal } from "../components/CertificationModal";
import DefaultImg from '../assets/images/corso-teaser-demo.png';

export const PublicSingleProduct = () => {
    const navigate = useNavigate();
    const { addToCart, isCourseAlreadyInCart } = useContext(CartContext);


    const { product, typology } = useLoaderData() as { product: any, typology: 'Course' | 'Live' };
    const [videos, setVideos] = useState<PublicVideo[]>();
    const [resources, setResources] = useState<ResourceDataElement[]>();

    // Imposto il titolo della pagina con il titolo del corso/live
    useEffect(() => {
        document.title = `${product?.Title} - ${TextDictionary.AppName}`;
        document.querySelector('meta[name="description"]')?.setAttribute('content', product?.Description || '');

        // Faccio in modo che una volta lasciare la pagina, il titolo torni quello di default

        return () => {
            document.title = TextDictionary.AppName;
            document.querySelector('meta[name="description"]')?.setAttribute('content', '');
        }
    }, [product]);


    useEffect(() => {
        const fetchVideos = async () => {
            const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/videos/${product.ID}`);
            setVideos(response.data);
        }

        if (product) {
            if (typology === 'Course') fetchVideos(); // Se il prodotto è un corso, allora fetcho i video
            setResources(product.ResourceData);
        }
    }, [product]);

    return (
        <PublicLayout>
            <div className="flex flex-col">
                <div className="flex flex-col md:flex-row justify-between items-center bg-primary w-full py-0 lg:py-12 px-8">
                    <div className="max-w-[1400px] flex flex-col-reverse w-full items-center mx-auto gap-0 lg:gap-20 lg:flex-row px-0 lg:px-8">
                        <div className="flex flex-col justify-center gap-4 flex-1 w-full h-full">
                            <div className="pb-8 lg:py-0 h-full">
                                <div className="flex flex-col gap-4 mb-8 lg:flex-row">
                                    {typology === 'Course' && (
                                        <span className="text-sm font-medium text-customYellow">{TextDictionary.ListaCorsi.LabelLezioni} <span className="text-white">{product?.VideoNumber ?? 0}</span></span>
                                    )}
                                    <span className="text-sm font-medium text-customYellow">{TextDictionary.ListaCorsi.LabelData} <span className="text-white">{product?.Date}</span></span>
                                    {
                                        product?.Duration && product?.Duration !== '' && (
                                            <span className="text-sm font-medium text-customYellow">{TextDictionary.ListaCorsi.LabelDurata} <span className="text-white">{product.Duration}</span></span>
                                        )
                                    }
                                </div>

                                <h1 className="text-white text-2xl lg:text-4xl font-medium mb-4 flex flex-row gap-4 items-center">{product?.Title} {product.Type === 'Live' &&
                                    <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.89 184.4" height={30} width={30}>
                                        <g id="Layer_1" data-name="Layer 1" fill="#ed1c24">
                                            <g>
                                                <g>
                                                    <path d="M109.48,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.27c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v32.43h19.28Z" />
                                                    <path d="M118.33,184.4c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v40.84c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-8.28Z" />
                                                    <path d="M150.57,184.4c-1.64,0-2.76-.84-3.35-2.53l-12.9-39.51c-.08-.29-.14-.48-.16-.57-.02-.08-.03-.17-.03-.25,0-.38.14-.71.41-.98.27-.27.6-.41.98-.41h7.97c.84,0,1.44.18,1.8.54.36.36.58.71.66,1.04l8.79,27.82,8.79-27.82c.08-.34.31-.68.66-1.04.36-.36.96-.54,1.8-.54h7.97c.38,0,.71.14.98.41.27.27.41.6.41.98,0,.08-.01.17-.03.25-.02.08-.07.27-.16.57l-12.9,39.51c-.59,1.69-1.71,2.53-3.35,2.53h-8.34Z" />
                                                    <path d="M212.84,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.97c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h29.46c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-19.47v6.83h18.08c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-18.08v7.08h19.98Z" />
                                                </g>
                                                <path d="M148.95,48.5c-15.53,0-28.12,12.59-28.12,28.12s12.59,28.12,28.12,28.12,28.12-12.59,28.12-28.12-12.59-28.12-28.12-28.12ZM166.7,79.02l-13.43,7.75-13.43,7.75c-1.85,1.07-4.16-.27-4.16-2.4v-31.01c0-2.13,2.31-3.47,4.16-2.4l13.43,7.75,13.43,7.75c1.85,1.07,1.85,3.73,0,4.8Z" />
                                                <g>
                                                    <path d="M233.04,133.36c-1.97,0-3.96-.54-5.74-1.68-4.97-3.17-6.43-9.78-3.26-14.76,7.67-12.02,11.73-25.96,11.73-40.29s-4.06-28.28-11.73-40.3c-3.18-4.97-1.72-11.58,3.26-14.76,4.97-3.18,11.58-1.72,14.76,3.26,9.87,15.47,15.09,33.38,15.09,51.8s-5.22,36.33-15.08,51.79c-2.04,3.19-5.49,4.94-9.02,4.94Z" />
                                                    <path d="M267.49,153.25c-1.92,0-3.86-.52-5.62-1.6-5.02-3.11-6.57-9.69-3.46-14.71,11.19-18.08,17.1-38.93,17.1-60.31s-5.91-42.23-17.1-60.31c-3.11-5.02-1.56-11.61,3.46-14.71,5.02-3.11,11.61-1.56,14.71,3.46,13.28,21.46,20.3,46.2,20.3,71.56s-7.02,50.1-20.3,71.56c-2.02,3.27-5.52,5.06-9.1,5.06Z" />
                                                    <path d="M198.85,113.01c-2.06,0-4.14-.59-5.97-1.83-4.89-3.3-6.18-9.95-2.87-14.84,3.94-5.83,6.02-12.64,6.02-19.71s-2.16-14.15-6.25-20.05c-3.36-4.85-2.15-11.51,2.7-14.87,4.85-3.36,11.51-2.15,14.87,2.7,6.57,9.49,10.05,20.63,10.05,32.22s-3.35,22.3-9.68,31.68c-2.07,3.06-5.44,4.71-8.87,4.71Z" />
                                                </g>
                                                <g>
                                                    <path d="M63.85,133.36c-3.53,0-6.98-1.75-9.02-4.94-9.87-15.46-15.09-33.37-15.09-51.79s5.22-36.34,15.09-51.8c3.18-4.98,9.78-6.43,14.76-3.26,4.98,3.18,6.44,9.78,3.26,14.76-7.67,12.02-11.73,25.96-11.73,40.3s4.06,28.27,11.73,40.29c3.18,4.98,1.71,11.58-3.26,14.76-1.78,1.14-3.77,1.68-5.74,1.68Z" />
                                                    <path d="M29.4,153.25c-3.58,0-7.08-1.8-9.1-5.06C7.02,126.72,0,101.98,0,76.62S7.02,26.52,20.3,5.06C23.41.04,30-1.51,35.02,1.6c5.02,3.11,6.57,9.69,3.46,14.71-11.19,18.08-17.1,38.93-17.1,60.31s5.91,42.23,17.1,60.31c3.11,5.02,1.56,11.61-3.46,14.71-1.75,1.08-3.7,1.6-5.62,1.6Z" />
                                                    <path d="M98.04,113.01c-3.43,0-6.8-1.65-8.87-4.71-6.33-9.37-9.68-20.33-9.68-31.68s3.48-22.72,10.05-32.22c3.36-4.85,10.02-6.06,14.87-2.7,4.85,3.36,6.06,10.02,2.7,14.87-4.09,5.9-6.25,12.83-6.25,20.05s2.08,13.89,6.02,19.71c3.3,4.89,2.02,11.53-2.87,14.84-1.83,1.24-3.91,1.83-5.97,1.83Z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                }</h1>
                                
                                {product?.Teacher && <p className="text-white text-xl mb-8">{TextDictionary.ListaCorsi.LabelDocente} {product?.Teacher}</p>}
                                <p
                                    dangerouslySetInnerHTML={{ __html: product?.ShortDescription || '' }}
                                    className="text-white text-md mb-8 text-opacity-70"
                                    style={{ overflowWrap: 'anywhere' }}
                                />

                                {!product.Access.CanView && (
                                    <span className="text-customYellow text-md"><span className="font-semibold">{product?.DiscountPrice ? `€${product?.DiscountPrice.toFixed(2)}` : `€${product?.RegularPrice.toFixed(2)}`}</span> {TextDictionary.ListaCorsi.LabelConAbbonamento}</span>
                                )}
                                {/* riscrivo lo span con i pressi in bold */}


                                {/* <button className="bg-customYellow text-primary flex items-center border-0 text-sm font-medium px-8 py-2 rounded-lg w-fit">Crea il tuo account gratis</button> */}
                                <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 mt-8 mb-8 lg:mb-4">
                                    {!AuthService.getInstance().accessToken && (
                                        <PrimaryButton text={TextDictionary.ListaCorsi.CreaAccountGratis} onClick={() => {
                                            navigate('/signup');
                                        }} inverse />
                                    )}

                                    {!product.Access.CanView && (
                                        <PrimaryButton text={TextDictionary.ListaCorsi.BottoneAcquista} onClick={() => {
                                            // Controllo se il corso è già nel carrello
                                            if (!isCourseAlreadyInCart(product)) { // Se il corso non è nel carrello, lo aggiungo
                                                addToCart(product);
                                            }
                                            navigate('/cart');
                                        }} />
                                    )}

                                    {
                                        !product.Access.CanView && ( // Andrà aggiunto controllo anche sulla subscription, se l'utente ha già acquistato la subscription o no
                                            <PrimaryButton text={TextDictionary.ListaCorsi.BottoneAbbonati} onClick={() => {
                                                // Controllo se il corso è già nel carrello
                                                navigate('/public/abbonamenti');
                                            }} inverse />
                                        )
                                    }

                                    {
                                        typology === 'Course' && videos && videos.length > 0 && AuthService.getInstance().accessToken && product.Access.CanView && (
                                            <PrimaryButton text={TextDictionary.ListaCorsi.BottoneGuardaCorso} onClick={() => {
                                                navigate(`/public/videos/${product.ID}/${videos?.[0].ID}`);
                                            }} />
                                        )
                                    }

                                    {
                                        typology === 'Live' && AuthService.getInstance().accessToken && product.Access.CanView && product.LiveMetadata && (
                                            <PrimaryButton text={TextDictionary.ListaCorsi.BottoneGuardaLive} onClick={() => {

                                                // /public/lives/streaming/
                                                navigate(`/public/lives/streaming/${product.ID}`);
                                            }} />
                                        )
                                    }

                                    {
                                        typology === 'Live' && AuthService.getInstance().accessToken && product.Access.CanView && !product.LiveMetadata && (
                                            <span className="text-white text-md mt-4 italic">{TextDictionary.ListaCorsi.LiveNonAncoraDisponibile}</span>
                                        )
                                    }

                                    {
                                        typology === 'Live' && AuthService.getInstance().accessToken && product.Access.CanView && product.CourseID && (
                                            <PrimaryButton text={TextDictionary.ListaCorsi.BottoneVaiAlCorsoCollegato} onClick={() => {

                                                // /public/lives/streaming/
                                                navigate(`/public/corsi/${product.CourseID}`);
                                            }} />
                                        )
                                    }
                                </div>
                                {
                                    !AuthService.getInstance().accessToken && (
                                        <span className="text-white text-md mt-4">{TextDictionary.ListaCorsi.TestoRegistratiPerAcquistare} <a href="/signin" className="underline">login</a> </span>
                                    )
                                }

                                {
                                    // Controllo se l'utente è admin
                                    AuthService.getInstance().isAdmin && (
                                        <PrimaryButton text={TextDictionary.ListaCorsi.ModificaCorso} onClick={() => {
                                            if (typology === 'Course') window.open(`/admin/courses/${product.ID}`, '_blank');
                                            if (typology === 'Live') window.open(`/admin/lives/${product.ID}`, '_blank');
                                        }} inverse />
                                    )
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 flex-1 w-full h-full justify-center items-center pb-8 lg:pb-0">
                            {
                                product?.EmbeddedPreviewVideoURLHTML && product?.EmbeddedPreviewVideoURLHTML !== '' ? (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: product?.EmbeddedPreviewVideoURLHTML || '' }}
                                        className="w-full flex items-center justify-center mb-8"
                                    />
                                ) : (
                                    product?.ImageURL ? (
                                        <img src={product?.ImageURL} alt="course" className="w-full object-contain rounded-sm" />
                                    ) : (
                                        <img src={DefaultImg} alt="placeholder" className="w-full h-72 object-contain rounded-sm" />
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="w-full h-auto" style={{
                    // Imposto un background bg-secondary solo per il 50% della larghezza della pagina
                    background: `linear-gradient(90deg, white 50%, ${AppConfig.secondaryColor} 50%)`
                }}>
                    <div className="flex flex-col w-full max-w-[1400px] mx-auto lg:flex-row">
                        <div className="w-full flex-1 bg-white px-8 py-12">
                            <h2 className="text-2xl lg:text-4xl font-bold text-primary bodoni mb-4">{TextDictionary.ListaCorsi.LabelDescrizioneCorso}</h2>
                            <p
                                dangerouslySetInnerHTML={{ __html: product?.Description || TextDictionary.ListaCorsi.NessunaDescrizione }}
                                className="text-md text-primary" style={{ overflowWrap: 'anywhere' }}
                            />
                        </div>
                        <div className="w-full flex-1 bg-secondary px-8 py-12">
                            <h2 className="text-2xl lg:text-4xl font-bold text-primary bodoni mb-4 text-left lg:text-center">{TextDictionary.ListaCorsi.LabelCosaComprende}</h2>
                            {/* Riscrivo la logica in modo più pulito */}
                            {
                                resources?.length === 0 ? (
                                    <div className="flex flex-col rounded-lg pb-4">
                                        <h3 className="text-md text-primary">
                                            {typology === 'Live' ? TextDictionary.ListaCorsi.NessunaRisorsaLive : TextDictionary.ListaCorsi.NessunaRisorsa}
                                        </h3>
                                    </div>
                                ) : (
                                    resources?.map((resource) => (
                                        <div key={resource.Title} className="flex flex-col rounded-lg pb-4">
                                            <h3 className="text-xl font-medium text-primary">{resource.Title}
                                                {
                                                    resource.FileName && resource.FileName !== '' && (
                                                        <span className="text-sm text-gray-500 pl-2">({resource.FileName})</span>
                                                    )
                                                }
                                            </h3>
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </div>

                {/* sezione video */}
                {typology === 'Course' ? ( // Visibile solo se il prodotto è un corso
                    <div className="flex flex-col w-full mx-auto bg-primary">
                        <div className="px-8 py-12 max-w-[1400px] mx-auto w-full">
                            <div className="flex flex-row justify-between items-center mb-8">
                                <span className="text-2xl lg:text-4xl font-bold text-customYellow">{product.Title}</span>
                                <span className="text-white text-md">{product.Duration}</span>
                            </div>
                            {
                                videos?.map((video) => (
                                    <div key={video.ID} className="flex flex-row justify-between items-center gap-4 py-8 border-t border-dotted border-t-white border-opacity-40">
                                        <div className="flex gap-4 items-center">

                                            {product.Access.CanView ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="white" width="20px" height="20px" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xmlSpace="preserve"><path d="M5.9,9.777C5.9,4.377,10.276,0,15.676,0c1.677,0,2.74,0.438,4.61,1.182l68.577,39.9c3.189,1.89,4.666,4.927,5.236,8.428  v0.982c-0.57,3.499-2.047,6.537-5.236,8.428L20.287,98.819c-1.871,0.745-2.934,1.181-4.61,1.181c-5.4,0-9.776-4.377-9.776-9.777  V9.777z" /></svg>
                                            ) : (
                                                <svg width="20px" height="20px" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.16667 12.8333V8.16659C7.16667 6.61949 7.78125 5.13576 8.87521 4.0418C9.96917 2.94783 11.4529 2.33325 13 2.33325C14.5471 2.33325 16.0308 2.94783 17.1248 4.0418C18.2188 5.13576 18.8333 6.61949 18.8333 8.16659V12.8333M4.83333 12.8333H21.1667C22.4553 12.8333 23.5 13.8779 23.5 15.1666V23.3333C23.5 24.6219 22.4553 25.6666 21.1667 25.6666H4.83333C3.54467 25.6666 2.5 24.6219 2.5 23.3333V15.1666C2.5 13.8779 3.54467 12.8333 4.83333 12.8333Z" stroke="#F5F5F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            )}

                                            <h3 className={`text-md font-medium text-white ${product.Access.CanView ? 'cursor-pointer hover:underline' : 'cursor-not-allowed'}`}
                                                onClick={() => {
                                                    if (product.Access.CanView) // Da aggiungere successivamente
                                                        navigate(`/public/videos/${product.ID}/${video.ID}`);
                                                }}>{video.Title}</h3>
                                        </div>
                                        <span className="text-white text-md">{video.Duration}</span> {/* qui ci andrà la durata del video */}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col w-full mx-auto bg-primary">
                        <div className="px-8 py-12 max-w-[1400px] mx-auto w-full flex flex-col gap-8 justify-center items-center">
                            <span className="text-2xl lg:text-4xl font-bold text-customYellow">{product.Title}</span>
                            {
                                typology === 'Live' && AuthService.getInstance().accessToken && product.Access.CanView && product.LiveMetadata && (
                                    <PrimaryButton text={TextDictionary.ListaCorsi.BottoneGuardaLive} onClick={() => {
                                        // /public/lives/streaming/
                                        navigate(`/public/lives/streaming/${product.ID}`);
                                    }} />
                                )
                            }

                            {
                                typology === 'Live' && AuthService.getInstance().accessToken && product.Access.CanView && !product.LiveMetadata && (
                                    <span className="text-white text-md mt-4 italic">{TextDictionary.ListaCorsi.LiveNonAncoraDisponibile}</span>
                                )
                            }

                            {
                                typology === 'Live' && AuthService.getInstance().accessToken && product.Access.CanView && product.CourseID && (
                                    <PrimaryButton text={TextDictionary.ListaCorsi.BottoneVaiAlCorsoCollegato} onClick={() => {

                                        // /public/lives/streaming/
                                        navigate(`/public/corsi/${product.CourseID}`);
                                    }} inverse />
                                )
                            }

                            {
                                !AuthService.getInstance().accessToken && (
                                    <PrimaryButton text={TextDictionary.ListaCorsi.BottoneAccediPerVedere} onClick={() => {
                                        navigate('/signin');
                                    }} inverse />)
                            }

                            {
                                AuthService.getInstance().accessToken && !product.Access.CanView && (
                                    <PrimaryButton text={TextDictionary.ListaCorsi.BottoneAcquista} onClick={() => {
                                        // Controllo se il corso è già nel carrello
                                        if (!isCourseAlreadyInCart(product)) { // Se il corso non è nel carrello, lo aggiungo
                                            addToCart(product)
                                        }
                                        navigate('/cart');
                                    }} inverse />
                                )
                            }
                        </div>
                    </div>
                )}

                {/* sezione corsi in evidenza */}
                <div className="w-full py-12 px-8 flex flex-col gap-10 max-w-[1400px] mx-auto">
                    <span className="text-4xl font-bold text-primary bodoni text-center italic">{TextDictionary.ListaCorsi.CorsiInEvidenza}</span>
                    <div className="w-full">
                        <FeauturedProducts inverse={false} />
                    </div>
                </div>

                {/* sezione banner abbonamento */}
                {/* <SubscriptionBanner /> */}
            </div>
        </PublicLayout>
    )
}