
// Importo il logo "logo.svg" dalla cartella public
export const ApiConfig = {
    API_URL: "https://carbognani-academy.bbsway.dev/api",
    ROOT_URL: "https://carbognani-academy.bbsway.dev/api",
    LOGIN_URL: "/auth/login",
    LOGOUT_URL: "/auth/logout",
    REGISTER_URL: "/auth/register",
    FORGOT_PASSWORD_URL: "/auth/forgot-password",
    RESET_PASSWORD_URL: "/auth/reset-password",
    REFRESH_URL: "/auth/refresh",
}

export const AppConfig = {
    // Qui ci andranno tutti gli asset che verranno utilizzati in più parti dell'applicazione, come ad esempio le icone, le immagini, logo, ecc.
    // Esempio:
    brandLogo: process.env.PUBLIC_URL + '/logo.svg', // Logo principale dell'applicazione
    // logoInverseSrc: require("/logo-inverse.png"), // Logo principale dell'applicazione in versione invertita
    primaryColor: '#004e59',
    secondaryColor: '#d6d1c4',
    customYellow: '#ffd112',
    errorRed: '#f5584d',
    gray: '#d0d0d0',
    lightGray: '#00000005',
}