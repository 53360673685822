import { useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { ProductCard } from "../components/ProductCard";
import { ApiConfig } from "../config/Configuration";
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import { Course } from "../models/course";
import Slider from "react-slick";
import { PrimaryButton } from "../components/PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";
import { FeauturedProducts } from "../components/FeauturedProducts";
import { axiosAuthPublicInstance } from "../auth/sso/auth.interceptor";

const PublicProducts = () => {
    const data = useLoaderData() as { products: any | null, endpoint: string | null, yearsEndpoint: string | null,  title: string };

    const [years, setYears] = useState<number[]>([]);
    const [activeYear, setActiveYear] = useState<number>(new Date().getFullYear());
    const [filteredProducts, setFilteredProducts] = useState<any[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getYears = async () => {
            const response = await axiosAuthPublicInstance.get(`${ApiConfig.API_URL}${data.yearsEndpoint}`);
            const years = response.data as number[]; // Array di tutti gli anni disponibili

            setYears(years);

            // Controllo se l'anno attivo è presente nell'array degli anni
            if (years.includes(activeYear)) {
                // Se l'anno attivo è presente, lo setto come active
                setActiveYear(activeYear);
            } else {
                // Se l'anno attivo non è presente, setto il primo anno dentro l'array come active
                setActiveYear(years[0]);
            }
        }

        if (data.endpoint && data.yearsEndpoint) // controllo se ho bisogno di scaricare i filtri per anni
            getYears();
    
    }, [data.endpoint]);

    useEffect(() => {
        if (data.endpoint) {
            setLoading(true);

            axiosAuthPublicInstance.get(`${ApiConfig.API_URL}${data.endpoint}?year=${activeYear}`).then((response) => {
                setFilteredProducts(response.data);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            });
        }
    }, [activeYear]);

    return (
        <PublicLayout>
            <div className="flex flex-col">
                <div className="flex flex-col md:flex-row justify-between items-center bg-primary w-full">
                    <div className="max-w-[1400px] flex flex-row w-full items-center justify-center mx-auto py-12 px-10">
                        <h1 className="text-white text-3xl md:text-6xl font-medium text-center mb-4 bodoni">{data.title}</h1>
                    </div>
                </div>

                {/* {data.endpoint && (
                    <div className="flex flex-row items-center justify-center gap-8 w-full pt-10 max-w-[1400px] mx-auto px-4 flex-wrap">
                        {
                            years.map((year, index) => (
                                <a key={index} onClick={() => setActiveYear(year)} className={`select-none text-black text-xl cursor-pointer ${activeYear === year ? 'text-primary underline font-bold' : ''}`}>{year}</a>
                            ))
                        }
                    </div>
                )} */}

                {data.endpoint && (
                    <div className="flex flex-row items-center justify-start md:justify-center gap-4 md:gap-8 w-full pt-10 max-w-[1400px] mx-auto px-4 flex-wrap">
                        {
                            years.map((year, index) => (
                                <a key={index} onClick={() => setActiveYear(year)} className={`select-none rounded-full text-xl cursor-pointer border border-primary text-primary px-6 py-1 ${activeYear === year ? 'bg-primary font-semibold text-white' : ''}`}>{year}</a>
                            ))
                        }
                    </div>
                )}

                <div className="flex flex-col justify-center items-center w-full py-10 max-w-[1400px] mx-auto px-4">
                    {loading ? (
                        <div className="text-xl font-medium text-primary mt-20">Caricamento...</div>
                    ) : (
                        <>
                            {!filteredProducts && (data.products && data.products.length > 0 ? (
                                <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 w-full">
                                    {
                                        data.products.map((product: any) => (
                                            <ProductCard key={product.ElementId} element={product} />
                                        ))
                                    }
                                </div>) : (
                                <span className="text-xl font-medium text-primary mt-20">{TextDictionary.ListaCorsi.NessunRisultato}</span>
                            ))}

                            {!data.products && (filteredProducts && filteredProducts.length > 0 ? (
                                <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 w-full">
                                    {
                                        filteredProducts.map((product: any) => (
                                            <ProductCard key={product.ElementId} element={product} />
                                        ))
                                    }
                                </div>) : (
                                <span className="text-xl font-medium text-primary mt-20">{TextDictionary.ListaCorsi.NessunRisultato}</span>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </PublicLayout>
    );
};

export default PublicProducts;
