import { useEffect, useState } from "react";
import { TextDictionary } from "../../utils/TextDictionary";
import { UserService } from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../../config/Configuration";
import Swal from "sweetalert2";
import { EmployeesData, PaymentDetails, UserSubscriptionData } from "../../models/user";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { EditPaymentMethod } from "./EditPaymentMethod";

const stripePromise = loadStripe('pk_test_51QVCo9BQN2JLpEWORz757kaaDOGayROpZmzs3uVQ0HaVJgmK7HoCrJCypPDUX4suUsMXahxvYRUrdXwoAgnzVswr00RAIQXLNw'); // Chiave pubblica di test di Stripe

export const UserSubscriptions = () => {
    const navigate = useNavigate();
    const [isCompilingEmployee, setIsCompilingEmployee] = useState(false);
    const [user, setUser] = useState(UserService.getInstance().user);

    const [options, setOptions] = useState<StripeElementsOptions | undefined>(undefined);

    const [editPaymentStates, setEditPaymentStates] = useState<Record<number, { showEditPaymentMethod: boolean; options?: StripeElementsOptions }>>({});

    const [showEditPaymentMethod, setShowEditPaymentMethod] = useState(false);
    const [isOpeningEditPaymentMethod, setIsOpeningEditPaymentMethod] = useState(false);


    useEffect(() => {
        const loadUserData = async () => {
            const userService = UserService.getInstance();
            const isUserAvailable = await userService.getUserData();
            if (isUserAvailable && userService.user)
                setUser(userService.user);
        };

        loadUserData();
    }, []);

    const saveEmployee = async (employee: EmployeesData, subscription: UserSubscriptionData) => {
        if (!user)
            return;

        if (!subscription.Employees)
            return;

        if (!employee.Email || !employee.Name || !employee.Surname) {
            return;
        }

        // Chiamo l'endpoint per salvare i dipendenti uno ad uno
        setIsCompilingEmployee(true);

        try {
            // /userSubscriptions/:userSubscriptionID/employee
            const payload = {
                Name: employee.Name,
                Surname: employee.Surname,
                Email: employee.Email
            };

            const response = await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/userSubscriptions/${subscription.ID}/employee`, payload);
            if (response.status >= 200) {
                // Imposto l'ID del dipendente prendendolo dalla response.data
                employee.ID = response.data.UserID;
                employee.UserID = response.data.UserID; // Imposto l'ID del dipendente effettivo
                employee.Name = response.data.Name; // Imposto il nome del dipendente effettivo
                employee.Surname = response.data.Surname; // Imposto il cognome del dipendente effettivo

                // const index = employees.findIndex((emp) => emp.Email === employee.Email);
                // setEmployees([...employees.slice(0, index), employee, ...employees.slice(index + 1)]); // Sostituisco l'elemento nell'array

                const newEmployees = [...subscription.Employees || []];
                newEmployees[subscription.Employees.indexOf(employee)] = employee; // Sostituisco l'elemento nell'array
                subscription.Employees = newEmployees; // Aggiungo il nuovo dipendente all'array [DA CONTROLLARE]
                const newUserSubscriptionData = [...user.UserSubscriptionData];
                const index = newUserSubscriptionData.findIndex((sub) => sub.ID === subscription.ID);
                if (index !== -1) {
                    newUserSubscriptionData[index].Employees = newEmployees;
                    setUser({ ...user, UserSubscriptionData: newUserSubscriptionData });
                }

                Swal.fire({
                    title: TextDictionary.Admin.Salvataggio.DipendenteAssociato,
                });
            }

            setIsCompilingEmployee(false);
        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: error.response.data.message
            });

            setIsCompilingEmployee(false);
        }
    }

    const deleteEmployee = async (employee: EmployeesData, subscription: UserSubscriptionData) => {
        if (!user)
            return;

        if (!subscription.Employees)
            return;

        if (!employee.ID) {
            const newEmployees = [...subscription.Employees || []];
            newEmployees.splice(newEmployees.indexOf(employee), 1); // Rimuovo l'elemento dall'array
            const newUserSubscriptionData = [...user.UserSubscriptionData];
            const index = newUserSubscriptionData.findIndex((sub) => sub.ID === subscription.ID);
            if (index !== -1) {
                newUserSubscriptionData[index].Employees = newEmployees;
                setUser({ ...user, UserSubscriptionData: newUserSubscriptionData });
            }

            return;
        }

        // Mostro un avviso di conferma
        const result = await Swal.fire({
            title: TextDictionary.Admin.Salvataggio.EliminaDipendente,
            showCancelButton: true,
            confirmButtonText: TextDictionary.Admin.Salvataggio.Conferma,
            cancelButtonText: TextDictionary.Admin.Salvataggio.Annulla
        });

        if (!result.isConfirmed) {
            return;
        }

        // Chiamo l'endpoint per eliminare il singolo dipendente
        setIsCompilingEmployee(true);
        try {
            // /userSubscriptions/:userSubscriptionID/employee
            const response = await axiosAuthInstance.delete(`${ApiConfig.ROOT_URL}/userSubscriptions/${subscription.ID}/employee/${employee.ID}`);

            if (response.status >= 200) {
                // const index = employees.findIndex((emp) => emp.Email === employee.Email);
                // setEmployees([...employees.slice(0, index), ...employees.slice(index + 1)]); // Elimino l'elemento dall'array

                const newEmployees = [...subscription.Employees || []];
                newEmployees.splice(newEmployees.indexOf(employee), 1); // Rimuovo l'elemento dall'array
                const newUserSubscriptionData = [...user.UserSubscriptionData];
                const index = newUserSubscriptionData.findIndex((sub) => sub.ID === subscription.ID);
                if (index !== -1) {
                    newUserSubscriptionData[index].Employees = newEmployees;
                    setUser({ ...user, UserSubscriptionData: newUserSubscriptionData });
                }
            }

            setIsCompilingEmployee(false);

        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: error.response.data.message
            });

            setIsCompilingEmployee(false);
        }
    }

    // const initEditPaymentMethod = async () => { // Inizializzo la modifica del metodo di pagamento
    //     setIsOpeningEditPaymentMethod(true);

    //     try {
    //         // 1. Contatto il server per ottenere il clientSecret
    //         const response = await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/users/stripe/setup-intent`);
    //         console.log("response", response);
    //         const clientSecret = response.data.ClientSecret;

    //         // 2. Aggiorno lo stato di options
    //         setOptions({
    //             clientSecret: clientSecret,
    //         });

    //         // 3. Rendo visibile la sezione di modifica del metodo di pagamento
    //         setShowEditPaymentMethod(true);

    //         setIsOpeningEditPaymentMethod(false);
    //     } catch (error: any) {
    //         console.error("initEditPaymentMethod error: ", error);

    //         Swal.fire({
    //             title: TextDictionary.GestioneAbbonamenti.ErroreInizializzazione,
    //             text: error.response?.data?.message
    //         });

    //         setIsOpeningEditPaymentMethod(false);
    //     }
    // }

    const initEditPaymentMethod = async (subscriptionID: number) => {
        setEditPaymentStates((prev) => ({
            ...prev,
            [subscriptionID]: { ...prev[subscriptionID], isOpening: true },
        }));

        try {
            // 1. Contatto il server per ottenere il clientSecret
            const response = await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/users/stripe/setup-intent`);
            const clientSecret = response.data.ClientSecret;

            // 2. Aggiorno lo stato di options per l'abbonamento specifico
            setEditPaymentStates((prev) => ({
                ...prev,
                [subscriptionID]: {
                    showEditPaymentMethod: true,
                    options: { clientSecret },
                    isOpening: false,
                },
            }));
        } catch (error: any) {
            console.error("initEditPaymentMethod error: ", error);

            Swal.fire({
                title: TextDictionary.GestioneAbbonamenti.ErroreInizializzazione,
                text: error.response?.data?.message,
            });

            setEditPaymentStates((prev) => ({
                ...prev,
                [subscriptionID]: { ...prev[subscriptionID], isOpening: false },
            }));
        }
    };

    const formatDate = (date: string) => {
        const dateObj = new Date(date);

        const day = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
        const month = dateObj.getMonth() + 1 < 10 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1;

        return `${day}/${month}/${dateObj.getFullYear()}`; // Restituisco la data nel formato gg/mm/aaaa
    }



    const handleEmployeeChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        subscriptionID: number,
        field: "Name" | "Surname" | "Email",
        employeeIndex: number
    ) => {
        if (!user) return;
        if (!user.UserSubscriptionData) return;

        const subIndex = user.UserSubscriptionData.findIndex(
            (sub) => sub.ID === subscriptionID
        );
        if (subIndex === -1) return;

        const subscriptionToUpdate = user.UserSubscriptionData[subIndex];
        if (!subscriptionToUpdate.Employees) return;

        const updatedEmployees = [...subscriptionToUpdate.Employees];
        updatedEmployees[employeeIndex] = {
            ...updatedEmployees[employeeIndex],
            [field]: e.target.value, // Usa la notazione con le parentesi per aggiornare la chiave dinamica
        };

        user.UserSubscriptionData[subIndex] = {
            ...subscriptionToUpdate,
            Employees: updatedEmployees,
        };

        setUser({
            ...user,
            UserSubscriptionData: [...user.UserSubscriptionData],
        });
    };

    const cancelSubscription = async (subscriptionID: number) => {
        const result = await Swal.fire({
            title: TextDictionary.GestioneAbbonamenti.TitoloDisdettaAbbonamento,
            text: TextDictionary.GestioneAbbonamenti.SottotitoloDisdettaAbbonamento,
            showCancelButton: true,
            confirmButtonText: TextDictionary.GestioneAbbonamenti.DisdettaAbbonamento,
            cancelButtonText: TextDictionary.GestioneAbbonamenti.Annulla,
            confirmButtonColor: AppConfig.errorRed,
        });

        if (!result.isConfirmed) {
            return;
        }

        try {
            const response = await axiosAuthInstance.put(`${ApiConfig.ROOT_URL}/auth/user/${subscriptionID}/cancellation`);
            if (response.status >= 200) {
                Swal.fire({
                    title: TextDictionary.GestioneAbbonamenti.TitoloDisdettaAbbonamentoConferma,
                    text: TextDictionary.GestioneAbbonamenti.SottotitoloDisdettaAbbonamentoConferma
                });
                window.location.reload();
            }
        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.GestioneAbbonamenti.DisdettaAbbonamentoErrore,
                text: error.response.data.message
            });
        }
    }

    const activateSubscription = async (subscriptionID: number) => {
        const result = await Swal.fire({
            title: TextDictionary.GestioneAbbonamenti.TitoloAttivaAbbonamento,
            text: TextDictionary.GestioneAbbonamenti.SottotitoloAttivaAbbonamento,
            showCancelButton: true,
            confirmButtonText: TextDictionary.GestioneAbbonamenti.AttivaAbbonamento,
            cancelButtonText: TextDictionary.GestioneAbbonamenti.Annulla
        });

        if (!result.isConfirmed) {
            return;
        }

        try {
            const response = await axiosAuthInstance.put(`${ApiConfig.ROOT_URL}/auth/user/${subscriptionID}/cancellation`);
            if (response.status >= 200) {
                Swal.fire({
                    title: TextDictionary.GestioneAbbonamenti.AttivaAbbonamentoConferma,
                    text: TextDictionary.GestioneAbbonamenti.AttivaAbbonamentoConfermaTesto
                });
                window.location.reload();
            }
        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.GestioneAbbonamenti.AttivaAbbonamentoErrore,
                text: error.response.data.message
            });
        }
    }

    return (

        <div className="w-full flex flex-col border-x md:border-x-0 border-b md:border-b-0">
            { /* creo un header con il titolo della pagina centrato */}
            <div className="flex flex-col justify-center items-center w-full py-4 border-y border-black border-opacity-10 gap-2 md:border-t-0 border-x md:border-x-0">
                <h1 className="text-black text-lg md:text-2xl font-medium">{TextDictionary.GestioneAbbonamenti.Titolo}</h1>
                <span className="text-black text-opacity-80 text-sm md:text-md">{TextDictionary.GestioneAbbonamenti.Sottotitolo}</span>
            </div>


            { /* CASO 1. L'utente non ha nessun abbonamento ne come dipendente ne come proprietario */}
            {
                !user?.UserSubscriptionEmployeeData && !user?.UserSubscriptionData && ( // Se l'utente non ha abbonamenti
                    <div className="flex flex-col items-center justify-center gap-8 p-8">
                        <span className="text-black italic text-center">{TextDictionary.GestioneAbbonamenti.NoAbbonamento}</span>
                        <PrimaryButton text={TextDictionary.GestioneAbbonamenti.CTAAbbonamento} onClick={() => navigate('/public/abbonamenti')} />
                    </div>
                )
            }

            { /* CASO 2. Gestione abbonamenti da proprietario di tali */}
            {
                user?.UserSubscriptionData && user?.UserSubscriptionData.length > 0 && (
                    user.UserSubscriptionData.map((subscription, index) => (
                        <div key={subscription.ID} className="flex flex-col border-b">
                            <div className="flex flex-col p-8 mb-4">
                                <div className="flex flex-col gap-2 p-[25px]">
                                    <div className="flex flex-col md:flex-row md:items-center gap-4">
                                        <span className="text-primary text-md lg:text-lg font-medium">{TextDictionary.GestioneAbbonamenti.AbbonamentoAttivo}:</span>
                                        <span className="text-black text-opacity-80 text-md lg:text-lg">{subscription.SubscriptionName}</span>
                                        <div className="flex flex-col md:flex-row md:items-center gap-2">
                                            {/* <span className="text-primary text-md lg:text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Stato}</span> */}
                                            {
                                                subscription.IsActive && subscription.IsCancelled && (
                                                    <span className="text-black text-md">( {TextDictionary.GestioneAbbonamenti.LabelAbbonamentoAttivoDisdetto} )</span>
                                                )
                                            }

                                            {
                                                subscription.IsActive && !subscription.IsCancelled && (
                                                    <span className="text-black text-md">( {TextDictionary.GestioneAbbonamenti.LabelAbbonamentoAttivo} )</span>
                                                )
                                            }

                                            {
                                                !subscription.IsActive && subscription.IsCancelled && (
                                                    <span className="text-black text-md">( {TextDictionary.GestioneAbbonamenti.LabelAbbonamentoDisdettoNoFatture} )</span>
                                                )
                                            }

                                            {
                                                !subscription.IsActive && !subscription.IsCancelled && (
                                                    <span className="text-black text-md">( {TextDictionary.GestioneAbbonamenti.LabelFattureNonPagate} )</span>
                                                )
                                            }

                                        </div>
                                    </div>
                                    <span className="text-black text-opacity-80 text-sm">{subscription.SubscriptionDescription}</span>
                                </div>

                                <div className="module-card flex flex-col mb-8">
                                    <div className="flex flex-col md:flex-row justify-between md:items-center mb-4 gap-4 md:gap-0">
                                        <div className="flex flex-col md:flex-row md:items-center gap-4 flex-1">
                                            <span className="text-primary text-md lg:text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Scadenza}</span>
                                            <span className="text-black text-md">{formatDate(subscription.ExpireDate)}</span>
                                        </div>

                                        {/* <div className="flex flex-col md:flex-row items-center md:items-center gap-4 flex-1">
                                            <span className="text-primary text-md lg:text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Stato}</span>
                                            {
                                                subscription.IsActive && subscription.IsCancelled && (
                                                    <span className="text-black text-md">{TextDictionary.GestioneAbbonamenti.LabelAbbonamentoAttivoDisdetto}</span>
                                                )
                                            }
                                            
                                            {
                                                subscription.IsActive && !subscription.IsCancelled && (
                                                    <span className="text-black text-md">{TextDictionary.GestioneAbbonamenti.LabelAbbonamentoAttivo}</span>
                                                )
                                            }

                                            {
                                                !subscription.IsActive && subscription.IsCancelled && (
                                                    <span className="text-black text-md">{TextDictionary.GestioneAbbonamenti.LabelAbbonamentoDisdettoNoFatture}</span>
                                                )
                                            }

                                            {
                                                !subscription.IsActive && !subscription.IsCancelled && (
                                                    <span className="text-black text-md">{TextDictionary.GestioneAbbonamenti.LabelFattureNonPagate}</span>
                                                )
                                            }
                                        </div> */}

                                    </div>

                                    {
                                        subscription.LastSuccessfulInvoiceDate && (
                                            <div className="flex flex-col md:flex-row md:items-center mb-4 gap-2">
                                                <span className="text-black text-md italic">{TextDictionary.GestioneAbbonamenti.LabelUltimoPagamentoRiuscito} {formatDate(subscription.LastSuccessfulInvoiceDate)}</span>
                                            </div>
                                        )
                                    }

                                    {
                                        subscription.LastFailedInvoiceDate && (
                                            <div className="flex flex-col md:flex-row md:items-center mb-4 gap-2">
                                                <span className="text-black text-md">{TextDictionary.GestioneAbbonamenti.LabelUltimoPagamentoFallito} {formatDate(subscription.LastFailedInvoiceDate)}</span>
                                            </div>
                                        )
                                    }

                                    {
                                        /* Faccio in modo che fin quando isFromOrder è null, venga mostrato un messaggio di caricamento */
                                        subscription.IsFromOrder === null && (
                                            <div className="flex flex-col md:flex-row md:items-center mb-4 gap-2">
                                                <span className="text-primary text-md lg:text-lg font-medium">{TextDictionary.GestioneAbbonamenti.MetodoPagamento}</span>
                                                <span className="text-black text-md">{TextDictionary.GestioneAbbonamenti.Caricamento}</span>
                                            </div>
                                        )
                                    }

                                    {
                                        subscription.IsFromOrder && subscription.ID && (
                                            <div className="flex flex-col mb-4 gap-2">
                                                <div className="flex-col md:flex-row md:items-center">
                                                    <span className="text-primary text-md lg:text-lg font-medium mr-4">{TextDictionary.GestioneAbbonamenti.MetodoPagamento}</span>
                                                    <span className="text-black text-md">{subscription.PaymentDetails?.Brand} **** **** **** {subscription.PaymentDetails?.Last4} ({subscription.PaymentDetails?.ExpMonth && subscription.PaymentDetails?.ExpMonth < 10 ? '0' + subscription.PaymentDetails?.ExpMonth : subscription.PaymentDetails?.ExpMonth}/{subscription.PaymentDetails?.ExpYear})</span>
                                                </div>
                                                <button
                                                    className="text-primary underline w-fit mt-2"
                                                    style={{ opacity: isOpeningEditPaymentMethod ? 0.5 : 1 }}
                                                    disabled={isOpeningEditPaymentMethod}
                                                    onClick={() => initEditPaymentMethod(subscription.ID as number)}
                                                >
                                                    {TextDictionary.GestioneAbbonamenti.TitoloModificaMetodoPagamento}
                                                </button>
                                            </div>
                                        )
                                    }

                                    <a className={`w-fit mt-4 underline cursor-pointer ${subscription.IsCancelled ? 'text-confirmGreen font-semibold text-xl' : 'text-red-600'}`} onClick={() => {
                                        if (!subscription.ID) return;

                                        if (!subscription.IsCancelled)
                                            cancelSubscription(subscription.ID);
                                        else
                                            activateSubscription(subscription.ID);
                                    }}>
                                        {subscription.IsCancelled ? TextDictionary.GestioneAbbonamenti.BottoneRiabilitaAbbonamento : TextDictionary.GestioneAbbonamenti.BottoneDisdettaAbbonamento}
                                    </a>

                                    {/* {
                                        subscription.IsFromOrder && options && options.clientSecret && showEditPaymentMethod && (
                                            <Elements stripe={stripePromise} options={options}>
                                                <EditPaymentMethod
                                                    clientSecret={options.clientSecret}
                                                    setShowEditPaymentMethod={setShowEditPaymentMethod}
                                                />
                                            </Elements>
                                        )
                                    } */}


                                    {
                                        subscription.ID &&
                                        subscription.IsFromOrder &&
                                        editPaymentStates[subscription.ID]?.options?.clientSecret &&
                                        editPaymentStates[subscription.ID]?.showEditPaymentMethod && (
                                            <Elements stripe={stripePromise} options={editPaymentStates[subscription.ID].options!}>
                                                <EditPaymentMethod
                                                    clientSecret={editPaymentStates[subscription.ID].options!.clientSecret!} // Passa il clientSecret corretto dell'abbonamento specifico
                                                    setShowEditPaymentMethod={() => {
                                                        setEditPaymentStates((prev) => ({
                                                            ...prev,
                                                            [subscription.ID as number]: {
                                                                ...prev[subscription.ID as number],
                                                                showEditPaymentMethod: false,
                                                            },
                                                        }));
                                                    }}
                                                />
                                            </Elements>
                                        )
                                    }
                                </div>

                                <div className="module-card flex flex-col mb-4 gap-2">
                                    <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 items-start lg:items-center mb-4">
                                        <div className="flex flex-row gap-8 items-center">
                                            <span className="text-primary text-md lg:text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Dipendenti} (max {subscription.EmployeesNumber})</span>
                                            {
                                                subscription.EmployeesNumber && subscription.Employees && subscription.Employees.length < subscription.EmployeesNumber && (
                                                    <button className="bg-primary text-white rounded-md px-3 py-1"
                                                        onClick={() => {
                                                            // Aggiungo un nuovo elemento all'array employees con valori nulli
                                                            const newEmployees = [...subscription.Employees || []];
                                                            newEmployees.push({
                                                                ID: null,
                                                                UserID: null,
                                                                Name: '',
                                                                Surname: '',
                                                                Email: ''
                                                            });

                                                            const newUserSubscriptionData = [...user.UserSubscriptionData];
                                                            const index = newUserSubscriptionData.findIndex((sub) => sub.ID === subscription.ID);
                                                            if (index !== -1) {
                                                                newUserSubscriptionData[index].Employees = newEmployees;
                                                                setUser({ ...user, UserSubscriptionData: newUserSubscriptionData });
                                                            }
                                                        }}
                                                        disabled={isCompilingEmployee} style={{ opacity: isCompilingEmployee ? 0.5 : 1 }}> +</button>
                                                )}
                                        </div>
                                        <span className="text-black text-opacity-80 text-md">
                                            {TextDictionary.GestioneAbbonamenti.CTADipendentiAggiuntivi} <a href={`mailto:academy@carbognani.srl`} className="underline">{TextDictionary.GestioneAbbonamenti.LabelMailTo}</a>
                                        </span>
                                    </div>


                                    <div className="flex flex-col gap-4">
                                        {
                                            subscription.Employees && subscription.Employees.length > 0 ? subscription.Employees.map((employee: EmployeesData, index) => (
                                                subscription.Employees && subscription.EmployeesNumber && index < subscription.EmployeesNumber && (
                                                    <div key={index} className={`flex flex-col lg:flex-row gap-4 lg:border-0 ${index !== subscription.Employees.length - 1 ? 'border-b border-black border-opacity-30 pb-8 lg:pb-2' : ''}`}>
                                                        <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employee.Name} onChange={(e) => {
                                                            if (!subscription.ID) return;
                                                            handleEmployeeChange(e, subscription.ID, "Name", index);
                                                        }} placeholder="Nome"
                                                            disabled={employee.ID ? true : false}
                                                            style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                        />
                                                        <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employee.Surname} onChange={(e) => {
                                                            if (!subscription.ID) return;
                                                            handleEmployeeChange(e, subscription.ID, "Surname", index);
                                                        }} placeholder="Cognome"
                                                            disabled={employee.ID ? true : false}
                                                            style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                        />
                                                        <input type="text" className={`border border-black border-opacity-10 px-4 py-1`} value={employee.Email} onChange={(e) => {
                                                            if (!subscription.ID) return;
                                                            handleEmployeeChange(e, subscription.ID, "Email", index);
                                                        }} placeholder="Email"
                                                            style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                            disabled={employee.ID ? true : false}
                                                        />
                                                        <div className="flex flex-row gap-4">
                                                            {
                                                                subscription.Employees[index] && (
                                                                    <button className="bg-errorRed text-white w-1/2 lg:w-20" onClick={() => {
                                                                        deleteEmployee(employee, subscription);
                                                                    }}>X</button>
                                                                )
                                                            }
                                                            {
                                                                employee.Email && employee.Name && employee.Surname && employee.ID === null && (
                                                                    <button className="bg-primary text-white px-4 py-1 w-1/2 lg:w-auto" onClick={() => {
                                                                        saveEmployee(employee, subscription);
                                                                    }} disabled={isCompilingEmployee} style={{ opacity: isCompilingEmployee ? 0.5 : 1 }}>
                                                                        Salva
                                                                    </button>
                                                                )
                                                            }
                                                        </div>
                                                    </div>)
                                            )) : (
                                                <span>{TextDictionary.Admin.Utenti.NessunDipendente}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                {/* <button className={`px-4 py-1 w-fit underline ${subscription.IsCancelled ? 'text-primary font-bold text-xl' : 'text-red-600'}`} onClick={() => {
                                    if (!subscription.ID) return;

                                    if (!subscription.IsCancelled)
                                        cancelSubscription(subscription.ID);
                                    else
                                        activateSubscription(subscription.ID);
                                }}>
                                    {subscription.IsCancelled ? TextDictionary.GestioneAbbonamenti.BottoneRiabilitaAbbonamento : TextDictionary.GestioneAbbonamenti.BottoneDisdettaAbbonamento}
                                </button> */}
                            </div>
                        </div>
                    ))
                )
            }

            { /* CASO 3. Gestione abbonamenti da dipendente */}
            {
                user?.UserSubscriptionEmployeeData && user?.UserSubscriptionEmployeeData.length > 0 && (
                    user.UserSubscriptionEmployeeData.map((subscription) => (
                        <div key={subscription.ID} className="module-card flex flex-col my-8">
                            <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.AbbonamentoAttivo}</span>
                            <div className="flex flex-col gap-2">
                                <span className="text-black text-opacity-80 text-sm">{subscription.SubscriptionName}</span>
                                <span className="text-black text-opacity-80 text-sm">{subscription.SubscriptionDescription}</span>
                            </div>

                            <div className="flex flex-col gap-4 mb-4">
                                <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Scadenza}</span>
                                <span className="text-black">{subscription.ExpireDate}</span>
                            </div>

                            <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Proprietario}</span>
                            <a className="text-black underline cursor-pointer"
                                href={`mailto:${subscription.UserSubscriptionOwnerEmail}`}
                            >
                                {subscription.UserSubscriptionOwnerEmail}
                            </a>
                        </div>
                    ))
                )
            }



        </div>
    );
}
