import Swal from "sweetalert2";
import { TextDictionary } from "../../utils/TextDictionary";
import { useStripe } from "@stripe/react-stripe-js";
import { axiosAuthInstance } from "../../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../../config/Configuration";
import App from "../../App";
import { useEffect, useState } from "react";

export const EditPaymentMethod = ({
    clientSecret,
    setShowEditPaymentMethod,
}: {
    clientSecret: string,
    setShowEditPaymentMethod: (value: boolean) => void,
}) => {
    const stripe = useStripe();
    const elements = stripe?.elements();
    const cardElement = elements?.create('card', {
        hidePostalCode: true,
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: AppConfig.primaryColor,
                color: '#31325F',
                fontWeight: 400,
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': { color: '#fce883' },
                '::placeholder': { color: '#CFD7E0' },
            },
            complete: {
                iconColor: AppConfig.primaryColor,
            },
        },
    });

    const [resultMessage, setResultMessage] = useState<string>('');
    const [changeDone, setChangeDone] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);


    useEffect(() => {
    cardElement?.mount('#card-element');
    }, [stripe]);

    const savePaymentMethod = async () => { // Salvo il metodo di pagamento
        setIsSaving(true); // Inizio il salvataggio

        // 1. Invoco la confirmCardSetup di Stripe
        if (!stripe || !clientSecret || !cardElement) {
            setIsSaving(false); // Fine del salvataggio
            return;
        }

        const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardElement
            }
        });

        if (error) {
            setResultMessage(error.message ?? ''); // Mostro il messaggio di errore
            setChangeDone(false); // Cambio non effettuato
            setIsSaving(false); // Fine del salvataggio

            return;
        }

        // 2. Notifico al server che il metodo di pagamento è stato aggiornato
        try {
            const response = await axiosAuthInstance.post(ApiConfig.ROOT_URL + '/users/stripe/save-payment-method', {
                PaymentMethodID: setupIntent.payment_method
            })

            if (response.status === 200) {
                setResultMessage(TextDictionary.GestioneAbbonamenti.MetodoPagamentoAggiornato + ' ' + TextDictionary.GestioneAbbonamenti.MetodoPagamentoAggiornatoDescrizione);
                setChangeDone(true); // Cambio effettuato
                setIsSaving(false); // Fine del salvataggio

                setTimeout(() => {
                    setShowEditPaymentMethod(false);
                    window.location.reload();
                }, 3000);
            }
        } catch (error: any) {
            console.error("ERRORR", error);

            setResultMessage(error.response?.data?.message ?? ''); // Mostro il messaggio di errore
            setChangeDone(false); // Cambio non effettuato

            setIsSaving(false); // Fine del salvataggio
        }
    }

    return (
        <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-2">
            <div className="bg-white p-8 rounded-lg w-[32rem]">
                <div className="flex flex-col gap-4">
                    <span className="text-xl font-semibold text-primary">{TextDictionary.GestioneAbbonamenti.TitoloModificaMetodoPagamento}</span>
                    <span className="text-md">{TextDictionary.GestioneAbbonamenti.DescrizioneModificaMetodoPagamento}</span>
                    <div id="card-element" className="my-6" />
                    <div className="flex flex-row justify-between items-center">
                        <button
                            className="text-errorRed"
                            onClick={() => setShowEditPaymentMethod(false)}
                            disabled={isSaving}
                        >
                            {TextDictionary.GestioneAbbonamenti.Annulla}
                        </button>

                        <button
                            className="text-primary underline font-semibold"
                            onClick={savePaymentMethod}
                            style={{ opacity: isSaving ? 0.5 : 1 }}
                            disabled={isSaving}
                        >
                            {TextDictionary.GestioneAbbonamenti.AggiornaMetodoPagamento}
                        </button>
                    </div>

                    {/* Messaggio codice sconto con animazione che scompare dopo 3 secondi */}
                    {resultMessage && <div className={`flex flex-row items-center justify-between border px-4 py-2 ${!changeDone ? 'border-errorRed bg-errorRed bg-opacity-10' : 'bg-successGreen bg-opacity-10 border-successGreen'}`}>
                        <p className="text-sm font-light">{resultMessage}</p>
                    </div>}
                </div>
            </div>
        </div>
    )
};