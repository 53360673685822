import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import Swal from "sweetalert2";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../config/Configuration";
import { BeginCheckoutRequest } from "./Payment";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../core/cartProvider";
import { useForm } from "react-hook-form";
import { UserService } from "../services/user.service";
import DefaultImg from '../assets/images/corso-teaser-demo.png';

interface CompleteCheckoutRequest {
    IntentID: string;
    InvoicingData: InvoicingData;
    StripeProductID: string | null;
    StripePriceID: string | null;
    StripeSubscriptionID: string | null;
}

interface CheckoutProps {
    intentID: string;
    checkoutBody: BeginCheckoutRequest;
    stripeProductID: string | null;
    stripePriceID: string | null;
    stripeSubscriptionID: string | null;
}

interface InvoicingData {
    Name: string;
    Surname: string;
    Email: string;
    Company?: string;
    Country: string;
    Address: string;
    ZIPCode: string;
    City: string;
    Province: string;
    TaxIdentifier: string; // PIVA o Codice Fiscale
    PEC_SDI: string; // PEC o SDI

    PrivacyPolicy: boolean;
}



const SubtotalElement = ({ id, productName, price, img, type }: { id: number, productName: string; price: number, img: string, type: string }) => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
            <div className="flex flex-row gap-4 items-center">
                <img src={img ? img : DefaultImg} alt="Product" className="w-8 h-8 object-contain cursor-pointer" onClick={() => {
                    if (type === 'Course')
                        navigate(`/public/corsi/${id}`)
                    else if (type === 'Live')
                        navigate(`/public/lives/${id}`)
                    else if (type === 'Subscription')
                        navigate(`/public/abbonamenti/`)
                }} />

                <p className="text-md cursor-pointer"
                    onClick={() => {
                        if (type === 'Course')
                            navigate(`/public/corsi/${id}`)
                        else if (type === 'Live')
                            navigate(`/public/lives/${id}`)
                        else if (type === 'Subscription')
                            navigate(`/public/abbonamenti/`)
                    }}
                >{productName}</p>
            </div>
            <p className="text-sm lg:text-md font-medium">{price?.toFixed(2)} €</p>
        </div>
    )
}

export const Checkout = ({
    intentID,
    checkoutBody,
    stripeProductID,
    stripePriceID,
    stripeSubscriptionID
}: CheckoutProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const { cartItems, getCartTotal, clearCart } = useContext(CartContext);
    const [cartTotalInfo, setCartTotalInfo] = useState<any>({});

    const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false);

    const [isUserLoaded, setIsUserLoaded] = useState(false);

    const { register, setValue, getValues, watch, control, handleSubmit, formState: { errors, isDirty, isSubmitting }, reset } = useForm<InvoicingData>({
        defaultValues: {
            Name: "",
            Surname: "",
            Email: "",
            Company: "",
            Country: "",
            Address: "",
            ZIPCode: "",
            City: "",
            Province: "",
            TaxIdentifier: "",
            PEC_SDI: "",

            PrivacyPolicy: false,
        }
    });

    useEffect(() => {
        if (!cartItems || cartItems.length === 0) {
            setCartTotalInfo({ SubTotal: 0, Total: 0, Coupons: [], SpecialDiscount: 0 });
            return;
        }

        const handleCartTotalInfo = async () => {
            setCartTotalInfo(await getCartTotal());
        }

        handleCartTotalInfo();
    }, [cartItems]);

    useEffect(() => {
        const loadUserData = async () => {
            const userService = UserService.getInstance();
            const isUserAvailable = await userService.getUserData(); // Carica i dati utente, se necessario.

            if (isUserAvailable && userService.user) {
                setValue('Name', userService.user.Name || '');
                setValue('Surname', userService.user.Surname || '');
                setValue('Email', userService.user.Email || '');
                setValue('Company', userService.user.Company || '');
            }
            setIsUserLoaded(true); // Segnala che l'utente è stato caricato.
        };

        loadUserData();
    }, []);


    const products = cartItems.map((product: any) => ({
        id: product.ID,
        productName: product.Title,
        price: product.DiscountPrice ? product.DiscountPrice : product.RegularPrice,
        img: product.ImageURL,
        type: product.Type
    }));

    const InputField = ({ placeholder, type = "text", field, isRequired, pattern, ...props }: { placeholder: string, type?: string, field: keyof InvoicingData, isRequired?: boolean, pattern?: string }) => (
        <>
            <input
                {...register(field, {
                    required: isRequired ? 'Campo obbligatorio' : false,
                    pattern: pattern ? { value: new RegExp(pattern), message: 'Formato non valido' } : undefined
                })}
                type={type}
                placeholder={placeholder}
                className="border border-black border-opacity-20 p-2 w-full"
                {...props}
            />
            {errors[field] && (
                <p className="text-errorRed text-sm">
                    {errors[field]?.type === 'required' && 'Campo obbligatorio'}
                    {errors[field]?.type === 'pattern' && errors[field]?.message}
                </p>
            )}
        </>
    );


    const completeCheckout = async () => {
        const body: CompleteCheckoutRequest = {
            ...checkoutBody,
            IntentID: intentID,
            InvoicingData: {
                Name: getValues().Name,
                Surname: getValues().Surname,
                Email: getValues().Email,
                Company: getValues().Company,
                Country: getValues().Country,
                Address: getValues().Address,
                ZIPCode: getValues().ZIPCode,
                City: getValues().City,
                Province: getValues().Province,
                TaxIdentifier: getValues().TaxIdentifier,
                PEC_SDI: getValues().PEC_SDI,

                PrivacyPolicy: getValues().PrivacyPolicy || false
            },
            StripeProductID: stripeProductID,
            StripePriceID: stripePriceID,
            StripeSubscriptionID: stripeSubscriptionID
        };

        const response = await axiosAuthInstance.post(ApiConfig.ROOT_URL + '/orders/complete-checkout', body);

        return response;
    }

    const handlePayment = async () => {
        setIsProcessingPayment(true);


        // Controllo se il form è valido
        if (!getValues().Name || !getValues().Surname || !getValues().Email || !getValues().Country || !getValues().ZIPCode || !getValues().City || !getValues().Province || !getValues().TaxIdentifier || !getValues().PEC_SDI) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Compila tutti i campi obbligatori',
            });


            setIsProcessingPayment(false);
            return;
        }

        try {
            if (!stripe || !elements) throw new Error("Stripe non è stato inizializzato correttamente");

            const checkoutResponse = await completeCheckout();
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                },
                redirect: 'if_required'
            })

            if (error) { // ERRORE DI STRIPE
                throw new Error(error.message || "Errore durante il pagamento");
            }

            if (paymentIntent?.status === "succeeded") { // STRIPE VA BENE
                try {
                    await axiosAuthInstance.post(ApiConfig.ROOT_URL + '/orders/confirm-checkout', { OrderID: checkoutResponse.data.OrderID });

                    const confirm = await Swal.fire({
                        icon: 'success',
                        title: 'Pagamento completato',
                        text: 'Il pagamento è stato completato con successo',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    });

                    setIsProcessingPayment(false);

                    clearCart();

                    // navigate('/profile/courses');

                    if (confirm.isConfirmed){
                        window.location.href = `/profile/courses`;
                    }

                } catch (error: any) {
                    throw new Error(`Errore durante la conferma dell'ordine: ${error.message}`);
                }
            } else {
                throw new Error(`Errore durante il pagamento: ${paymentIntent?.status}`);
            }

        } catch (error: any) {
            setIsProcessingPayment(false);
            console.error("Error during checkout completion:", error);
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: error.message,
            });
        }
    }


    return (
        <PublicLayout>
            <div className="max-w-[1400px] flex flex-col w-full items-start mx-auto my-12 px-4 overflow-y-auto md:px-12">
                <h1 className="text-4xl font-medium mb-12 bodoni">{TextDictionary.Carrello_Checkout.TitoloCheckout}</h1>
                <div className="w-full flex flex-col gap-4 md:flex-row">
                    <div className="w-full md:w-3/5 flex flex-col gap-10">
                        <div className="flex flex-col border border-black border-opacity-20 w-full">
                            <div className="flex flex-row items-center border-b border-black border-opacity-20 p-4">
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelDettagliDiFatturazione}</p>
                            </div>
                            <div className="flex flex-col gap-4 p-4">
                                {
                                    !isUserLoaded ? <p>Caricamento...</p> : (
                                        <>
                                            <input type="text" placeholder="Nome*" className="border border-black border-opacity-20 p-2 w-full" {...register('Name', { required: 'Campo obbligatorio' })} />
                                            {errors.Name && <p className="text-errorRed text-sm">{errors.Name.message}</p>}
                                            <input type="text" placeholder="Cognome*" className="border border-black border-opacity-20 p-2 w-full" {...register('Surname', { required: 'Campo obbligatorio' })} />
                                            {errors.Surname && <p className="text-errorRed text-sm">{errors.Surname.message}</p>}
                                            <input type="email" placeholder="Email*" className="border border-black border-opacity-20 p-2 w-full" {...register('Email', { required: 'Campo obbligatorio', pattern: { value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: 'Formato non valido' } })} />
                                            {errors.Email?.type === 'required' && <p className="text-errorRed text-sm">{errors.Email?.message}</p>}
                                            {errors.Email?.type === 'pattern' && <p className="text-errorRed text-sm">{errors.Email?.message}</p>}
                                            <input type="text" placeholder="Azienda" className="border border-black border-opacity-20 p-2 w-full" {...register('Company')} />
                                            <input type="text" placeholder="Paese*" className="border border-black border-opacity-20 p-2 w-full" {...register('Country', { required: 'Campo obbligatorio' })} />
                                            {errors.Country && <p className="text-errorRed text-sm">{errors.Country.message}</p>}
                                            <input type="text" placeholder="Indirizzo*" className="border border-black border-opacity-20 p-2 w-full" {...register('Address', { required: 'Campo obbligatorio' })} />
                                            {errors.Address && <p className="text-errorRed text-sm">{errors.Address.message}</p>}
                                            <input type="text" placeholder="CAP*" className="border border-black border-opacity-20 p-2 w-full" {...register('ZIPCode', { required: 'Campo obbligatorio' })} />
                                            {errors.ZIPCode && <p className="text-errorRed text-sm">{errors.ZIPCode.message}</p>}
                                            <input type="text" placeholder="Città*" className="border border-black border-opacity-20 p-2 w-full" {...register('City', { required: 'Campo obbligatorio' })} />
                                            {errors.City && <p className="text-errorRed text-sm">{errors.City.message}</p>}
                                            <input type="text" placeholder="Provincia*" className="border border-black border-opacity-20 p-2 w-full" {...register('Province', { required: 'Campo obbligatorio' })} />
                                            {errors.Province && <p className="text-errorRed text-sm">{errors.Province.message}</p>}
                                            <input type="text" placeholder="Codice Fiscale / Partita IVA*" className="border border-black border-opacity-20 p-2 w-full" {...register('TaxIdentifier', { required: 'Campo obbligatorio' })} />
                                            {errors.TaxIdentifier && <p className="text-errorRed text-sm">{errors.TaxIdentifier.message}</p>}
                                            <input type="text" maxLength={7} placeholder="PEC/SDI*" className="border border-black border-opacity-20 p-2 w-full" {...register('PEC_SDI', { required: 'Campo obbligatorio', maxLength: 7 })} />
                                            <span className="font-normal text-sm text-black text-opacity-80 pl-2 mt-[-5px]" >{TextDictionary.Carrello_Checkout.InformazioniPECSDI}</span>
                                            {errors.PEC_SDI && <p className="text-errorRed text-sm">{errors.PEC_SDI.message}</p>}
                                            
                                        </>
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-2/5 h-fit flex flex-col gap-8">
                        <div className="flex flex-col border border-black border-opacity-20">
                            <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelProdotto}</p>
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelSubtotale}</p>
                            </div>
                            {products.map((product: any, index: number) => (
                                <SubtotalElement key={index} {...product} />
                            ))}

                            {cartTotalInfo.SpecialDiscount && cartTotalInfo.SpecialDiscount > 0 ? (
                                <div className="border-b border-black border-opacity-20 p-4">
                                    <div className="flex items-center justify-between w-full">
                                        <p className="text-md italic">{TextDictionary.Carrello_Checkout.LabelScontoSpeciale}</p>
                                        <p className="text-md">- {cartTotalInfo.SpecialDiscount?.toFixed(2)} €</p>
                                    </div>
                                </div>
                            ) : null}

                            {cartTotalInfo.Coupons && Object.keys(cartTotalInfo.Coupons).length > 1 && (
                                <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4 w-full">
                                    {Object.keys(cartTotalInfo.Coupons).map((key: any, index: number) => {
                                        if (key !== 'Total') return (
                                            <div className="flex items-center justify-between w-full">
                                                <p className="text-md italic">{key}</p>
                                                <p className="text-md">- {cartTotalInfo.Coupons[key]?.toFixed(2)} €</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {cartTotalInfo.VAT && cartTotalInfo.VAT > 0 ? (
                                <div className="border-b border-black border-opacity-20 p-4">
                                    <div className="flex items-center justify-between w-full">
                                        <p className="text-md">{TextDictionary.Carrello_Checkout.LabelIVA}</p>
                                        <p className="text-md">{cartTotalInfo.VAT?.toFixed(2)} €</p>
                                    </div>
                                </div>
                            ) : null}

                            <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                                <p className="text-lg font-medium">{cartTotalInfo.Total?.toFixed(2)} €</p>
                                {/* <p className="text-lg font-medium">{getCartTotal()} €</p> */}
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 p-4 border border-black border-opacity-20">
                            <PaymentElement />

                            <span className="text-sm text-gray-500 my-4 text-[0.75rem] leading-[1rem]">{TextDictionary.Carrello_Checkout.TestoNotaMetodoPagamento}</span>

                            <div className="flex flex-row gap-4">
                                <input type="checkbox" className="border border-black border-opacity-20 p-2" {...register('PrivacyPolicy', { required: TextDictionary.Carrello_Checkout.AvvisoTerminiCondizioni })} />
                                <span className="text-sm text-gray-500">{TextDictionary.Carrello_Checkout.TestoNotaPagamento}<a href="https://carbognani.srl/termini-e-condizioni" target="_blank" className="underline font-medium text-primary">link</a></span>
                            </div>
                            

                            {errors.PrivacyPolicy && <p className="text-errorRed text-sm">{errors.PrivacyPolicy.message}</p>}

                            <button className="bg-primary text-white text-lg font-medium py-2 px-8" onClick={() => {
                                handleSubmit(handlePayment)();
                            }} disabled={!stripe || isProcessingPayment} style={{ cursor: !stripe || isProcessingPayment ? 'not-allowed' : 'pointer', opacity: !stripe || isProcessingPayment ? 0.5 : 1 }}>
                                {TextDictionary.Carrello_Checkout.BottoneEffettuaOrdine}
                            </button>
            
                        </div>
                    </div>
                </div>
            </div>

        </PublicLayout>
    );
};
