import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import HeaderTexture from "../assets/images/texture-hero-home.png";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { ProductCard } from "../components/ProductCard";
import { Course } from "../models/course";
import { useEffect, useState } from "react";
import { FeauturedProducts } from "../components/FeauturedProducts";
import HomeImage from "../assets/images/home-image.png";
import { PrimaryButton } from "../components/PrimaryButton";

import { Vantaggi } from "../components/Vantaggi";
import { LiveBannerComponent, StartingLiveData } from "../components/LiveBannerComponent";
import { axiosAuthPublicInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";

const Home = () => {
    const navigate = useNavigate();
    const products = useLoaderData() as any; // Course | Live

    const [liveBannerData, setLiveBannerData] = useState<StartingLiveData | null>(null);
    const [isLiveOn, setIsLiveOn] = useState(false);

    // Faccio in modo che parta una chiamata asincrona 
    // per vedere se c'è una live attiva

    useEffect(() => {
        const checkLives = async () => {
            const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/lives/streaming`);
            console.log(response.data);
            
            if (response.data) {
                setIsLiveOn(true);
                setLiveBannerData({
                    ID: response.data.ID,
                    Title: response.data.Title,
                    Description: response.data.ShortDescription,
                    Image: response.data.ImageURL,
                    Teacher: response.data.Teacher,
                    CanView: response.data.CanView,
                });
            }
        };

        checkLives();
    }, []);

    return (
        <PublicLayout>
            <div className="flex flex-col h-full w-full">
                {
                    isLiveOn && liveBannerData &&(
                        <LiveBannerComponent
                            startingLive={liveBannerData}
                        />
                    )
                }
                <div className="flex flex-col justify-between items-center bg-primary w-full" style={{ backgroundImage: `url(${HeaderTexture})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="hero-container max-w-[1400px] flex flex-col md:flex-row w-full items-center mx-auto py-20 px-0 md:px-20 h-[60vh] md:h-[70vh]">
                        <div className="flex flex-col justify-center gap-4 w-full md:w-[500px] h-full">
                            <div className="py-6 md:py-0 px-10 md:px-0">
                                <h1 className="text-white text-4xl md:text-7xl font-medium mb-10">{TextDictionary.Home.Titolo}</h1>
                                <p className="text-white text-md md:text-xl mb-10" dangerouslySetInnerHTML={{ __html: TextDictionary.Home.Contenuto }}></p>
                                <div className="flex flex-col md:flex-row gap-8 items-center">
                                    <PrimaryButton text={TextDictionary.Home.CTA_Formazione} onClick={() => {
                                        navigate("/public/corsi");
                                    }} />
                                    <a href="https://carbognani.srl/carbognani-custom-academy" target="_blank" className="text-white text-md underline flex flex-row gap-2 items-center">
                                        {TextDictionary.Home.CTA_Landing}
                                        <svg width="12px" height="12px" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.369 7.38378L10.369 7.53378H10.519L11.0774 7.53378L11.2274 7.53378V7.38378V0.922726L11.2274 0.772726L11.0774 0.772726L4.61634 0.772725L4.46634 0.772725L4.46634 0.922725L4.46634 1.48109L4.46634 1.63109L4.61634 1.63109L9.75806 1.63109L0.521969 10.8672L0.415903 10.9732L0.521969 11.0793L0.920799 11.4781L1.02687 11.5842L1.13293 11.4781L10.369 2.24205V7.38378Z" fill="white" stroke="white" strokeWidth="0.3" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:flex flex-col gap-4 flex-1 w-full h-full justify-center">
                            <img src={HomeImage} alt="header texture" className="w-full h-auto" />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center w-full gap-16 lg:gap-32 pb-10 max-w-[1400px] mx-auto">
                    <div className="w-full h-auto">
                        <h2 className="text-5xl font-bold text-center text-primary bodoni mb-12 mt-14 italic">{TextDictionary.Home.CorsiEvidenza}</h2>
                        <div className="w-full">
                            <FeauturedProducts />
                        </div>
                    </div>

                    <div className="w-full h-auto">
                        <h2 className="text-5xl font-bold text-center text-primary bodoni mb-12 italic">{TextDictionary.Home.ProssimiCorsi}</h2>
                        <div className="w-full">
                            {
                                !products || products.length === 0 ? (
                                    <div className="flex flex-col justify-center items-center w-full h-full">
                                        <span className="text-xl font-medium text-primary">{TextDictionary.Home.NessunCorso}</span>
                                    </div>
                                ) : (
                                    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-8 w-full px-4">
                                        {
                                            products.map((course: any) => (
                                                <ProductCard key={course.ElementID} element={course} inverse={false} />
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <Link to="/public/corsi" className="text-primary text-lg font-semibold underline">{TextDictionary.Home.ScopriTuttiCorsi}</Link>
                </div>

                <div className="flex flex-col justify-center items-center w-full mx-auto bg-[#d9d9d9]">
                    <Vantaggi />
                </div>
            </div>

        </PublicLayout>
    );
};

export default Home;