import { AuthService } from "../auth/sso/auth.service";
import { Live } from "../models/live";
import { TextDictionary } from "../utils/TextDictionary";

export interface LiveBannerComponentProps {
    startingLive: StartingLiveData;
}

export interface StartingLiveData {
    ID: number;
    Title: string;
    Description: string;
    Image: string;
    Teacher: string;
    CanView: boolean;
}

export const LiveBannerComponent = ({ startingLive }: LiveBannerComponentProps) => {
    return (
        <div className="max-w-[1200px] flex flex-col md:flex-row w-full items-center justify-center mx-auto py-14 px-10 gap-4 md:gap-28">
            <div className="w-[60%] md:w-1/3 h-auto flex md:block relative">
                <div className="w-full aspect-square rounded-full relative bg-gradient-to-br from-[#e1e5e5] to-[#457a81] mb-4 md:mb-0 overflow-hidden">
                    <img className="h-full object-cover left-0 relative object-left p-4 rounded-full" src={startingLive.Image} alt="Live Image" />

                    {startingLive.Teacher &&
                        <div className="hidden px-4 py-2 bg-customYellow absolute top-1/2 left-0 transform -translate-y-1/2 md:flex flex-col rounded-r-full" style={{
                            width: 'calc(50% + 25px)',
                        }}>
                            <span className="text-primary text-xs md:text-sm">{startingLive.Teacher}</span>
                        </div>
                    }
                </div>
                <div className="absolute h-14 w-14 md:h-24 md:w-24 rounded-full flex justify-center items-center top-0 right-0 bg-white shadow-md">
                    <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.89 184.4" className="h-10 w-10 md:h-14 md:w-14">
                        <g id="Layer_1" data-name="Layer 1" fill="#ed1c24">
                            <g>
                                <g>
                                    <path d="M109.48,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.27c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v32.43h19.28Z" />
                                    <path d="M118.33,184.4c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h8.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v40.84c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-8.28Z" />
                                    <path d="M150.57,184.4c-1.64,0-2.76-.84-3.35-2.53l-12.9-39.51c-.08-.29-.14-.48-.16-.57-.02-.08-.03-.17-.03-.25,0-.38.14-.71.41-.98.27-.27.6-.41.98-.41h7.97c.84,0,1.44.18,1.8.54.36.36.58.71.66,1.04l8.79,27.82,8.79-27.82c.08-.34.31-.68.66-1.04.36-.36.96-.54,1.8-.54h7.97c.38,0,.71.14.98.41.27.27.41.6.41.98,0,.08-.01.17-.03.25-.02.08-.07.27-.16.57l-12.9,39.51c-.59,1.69-1.71,2.53-3.35,2.53h-8.34Z" />
                                    <path d="M212.84,174.28c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-29.97c-.46,0-.86-.17-1.2-.51-.34-.34-.51-.74-.51-1.2v-40.84c0-.46.17-.86.51-1.2.34-.34.74-.51,1.2-.51h29.46c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-19.47v6.83h18.08c.46,0,.86.17,1.2.51.34.34.51.74.51,1.2v6.7c0,.46-.17.86-.51,1.2-.34.34-.74.51-1.2.51h-18.08v7.08h19.98Z" />
                                </g>
                                <path d="M148.95,48.5c-15.53,0-28.12,12.59-28.12,28.12s12.59,28.12,28.12,28.12,28.12-12.59,28.12-28.12-12.59-28.12-28.12-28.12ZM166.7,79.02l-13.43,7.75-13.43,7.75c-1.85,1.07-4.16-.27-4.16-2.4v-31.01c0-2.13,2.31-3.47,4.16-2.4l13.43,7.75,13.43,7.75c1.85,1.07,1.85,3.73,0,4.8Z" />
                                <g>
                                    <path d="M233.04,133.36c-1.97,0-3.96-.54-5.74-1.68-4.97-3.17-6.43-9.78-3.26-14.76,7.67-12.02,11.73-25.96,11.73-40.29s-4.06-28.28-11.73-40.3c-3.18-4.97-1.72-11.58,3.26-14.76,4.97-3.18,11.58-1.72,14.76,3.26,9.87,15.47,15.09,33.38,15.09,51.8s-5.22,36.33-15.08,51.79c-2.04,3.19-5.49,4.94-9.02,4.94Z" />
                                    <path d="M267.49,153.25c-1.92,0-3.86-.52-5.62-1.6-5.02-3.11-6.57-9.69-3.46-14.71,11.19-18.08,17.1-38.93,17.1-60.31s-5.91-42.23-17.1-60.31c-3.11-5.02-1.56-11.61,3.46-14.71,5.02-3.11,11.61-1.56,14.71,3.46,13.28,21.46,20.3,46.2,20.3,71.56s-7.02,50.1-20.3,71.56c-2.02,3.27-5.52,5.06-9.1,5.06Z" />
                                    <path d="M198.85,113.01c-2.06,0-4.14-.59-5.97-1.83-4.89-3.3-6.18-9.95-2.87-14.84,3.94-5.83,6.02-12.64,6.02-19.71s-2.16-14.15-6.25-20.05c-3.36-4.85-2.15-11.51,2.7-14.87,4.85-3.36,11.51-2.15,14.87,2.7,6.57,9.49,10.05,20.63,10.05,32.22s-3.35,22.3-9.68,31.68c-2.07,3.06-5.44,4.71-8.87,4.71Z" />
                                </g>
                                <g>
                                    <path d="M63.85,133.36c-3.53,0-6.98-1.75-9.02-4.94-9.87-15.46-15.09-33.37-15.09-51.79s5.22-36.34,15.09-51.8c3.18-4.98,9.78-6.43,14.76-3.26,4.98,3.18,6.44,9.78,3.26,14.76-7.67,12.02-11.73,25.96-11.73,40.3s4.06,28.27,11.73,40.29c3.18,4.98,1.71,11.58-3.26,14.76-1.78,1.14-3.77,1.68-5.74,1.68Z" />
                                    <path d="M29.4,153.25c-3.58,0-7.08-1.8-9.1-5.06C7.02,126.72,0,101.98,0,76.62S7.02,26.52,20.3,5.06C23.41.04,30-1.51,35.02,1.6c5.02,3.11,6.57,9.69,3.46,14.71-11.19,18.08-17.1,38.93-17.1,60.31s5.91,42.23,17.1,60.31c3.11,5.02,1.56,11.61-3.46,14.71-1.75,1.08-3.7,1.6-5.62,1.6Z" />
                                    <path d="M98.04,113.01c-3.43,0-6.8-1.65-8.87-4.71-6.33-9.37-9.68-20.33-9.68-31.68s3.48-22.72,10.05-32.22c3.36-4.85,10.02-6.06,14.87-2.7,4.85,3.36,6.06,10.02,2.7,14.87-4.09,5.9-6.25,12.83-6.25,20.05s2.08,13.89,6.02,19.71c3.3,4.89,2.02,11.53-2.87,14.84-1.83,1.24-3.91,1.83-5.97,1.83Z" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <div className="w-full md:w-2/3 h-auto flex flex-col justify-center items-center md:items-start gap-4">
                <span className="hidden md:block text-red-600 font-semibold text-xl mb-4">{TextDictionary.Home.LiveInCorso}</span>
                {startingLive.Teacher &&
                    <span className="block md:hidden text-primary text-sm">{startingLive.Teacher}</span>
                }
                <span className="text-primary text-xl md:text-3xl font-medium text-center md:text-left mb-4 md:mb-0">{startingLive.Title}</span>
                <span className="hidden md:block text-primary text-xl py-2 text-center md:text-left">{startingLive.Description}</span>
                <button className="bg-red-600 border border-red-600 text-white text-md md:text-lg rounded-full py-4 px-12 hover:text-red-600 hover:bg-transparent" onClick={() => {
                    if (!AuthService.getInstance().accessToken)  // Se l'utente non è loggato, lo porto alla pagina di anteprima della live
                        window.location.href = '/public/lives/' + startingLive.ID;
                    else if (AuthService.getInstance().accessToken && startingLive.CanView)  // Se l'utente è loggato e possiede l'accesso alla live, lo porto direttamente alla live
                        window.location.href = '/public/lives/streaming/' + startingLive.ID;
                    else if (AuthService.getInstance().accessToken && !startingLive.CanView)  // Se l'utente è loggato ma non possiede l'accesso alla live, lo porto alla pagina di anteprima
                        window.location.href = '/public/lives/' + startingLive.ID;
                }}>{TextDictionary.Home.BottoneBannerLive}</button>
            </div>
        </div>
    )
};